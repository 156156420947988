var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"academic-years"},[_c('lenon-list-page',{attrs:{"title":"Fee Settings","columns":_vm.columns,"rows":_vm.feeSettings,"table-loading":_vm.tableLoading,"show-create":false,"show-refresh":false,"show-search":true,"show-details":true,"sort-column":"display_date","sort-dir":"desc","search-placeholder":"Search"},scopedSlots:_vm._u([{key:"row-details",fn:function(ref){
var row = ref.row;
return [_c('b-card',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('strong',[_vm._v("Classes ")]),_c('b-row',{staticClass:"px-1"},_vm._l((row.item.classes),function(school_class,index){return _c('div',{key:index,staticStyle:{"margin-bottom":"5px","margin-right":"3px"}},[_c('b-badge',{attrs:{"variant":"light-success"}},[_vm._v(" "+_vm._s(_vm.reducedClasses[+school_class])+" ")])],1)}),0)],1)],1)],1)]}},{key:"index",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.index + 1)+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('b-badge',{attrs:{"variant":_vm.getStatusVariant(row.item.status)}},[_vm._v(" "+_vm._s(_vm.getStatusText(row.item.status))+" ")])]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('lenon-button',{attrs:{"icon-only":"","variant":"flat-primary","icon":row.detailsShowing?'EyeOffIcon':'EyeIcon'},on:{"onClick":row.toggleDetails}})]}}])},[_c('template',{slot:"right-extra-header-actions"},[_c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:(_vm.$store.getters['app/largeScreen']),expression:"$store.getters['app/largeScreen']"}],staticClass:"d-flex align-self-center align-items-start"},[_c('lenon-button',{attrs:{"icon":"SettingsIcon","label":"Fee Setting","tool-tip-text":"Manage Fee Settings(CRUD)"},on:{"onClick":function($event){return _vm.showFeeSettingModal()}}})],1),_c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:(_vm.$store.getters['app/smallScreen']),expression:"$store.getters['app/smallScreen']"}],staticClass:"d-flex align-self-center align-items-start"},[_c('lenon-dropdown',{attrs:{"left":""}},[_c('lenon-dropdown-item',{attrs:{"icon":"SettingsIcon","text":"Fee Setting"},on:{"click":function($event){return _vm.showFeeSettingModal()}}})],1)],1)])],2),_c('fee-setting-setup',{attrs:{"modal-opened":_vm.feeSettingModalOpened},on:{"modalClosed":function($event){return _vm.closeModal('feeSetting')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }