<template>
  <!--    FeeSetting Form-->
  <validation-observer
    ref="feeSettingForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateFeeSettingMode?'Update':'Create'} Fee Setting`"
      :show="feeSettingModalOpened"
      :show-overlay="deletingFeeSetting"
      size="md"
      @onClose="closeFeeSettingModal()"
    >
      <error-display :error="error" />
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedFeeSettingId"
            :options="feeSettings"
            label-name="display_name"
            value-name="id"
            placeholder="Fee Settings"
            @input="populateFeeSettingForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteFeeSetting()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetFeeSettingForm()"
          />
        </div>
      </b-row>
      <div class="row mb-1">
        <div class="col-12">
          <b-form-checkbox v-model="customAy">
            Custom Academic Year/Term
          </b-form-checkbox>
        </div>
      </div>
      <div v-if="customAy">
        <lenon-select
          v-model="feeSetting.academic_year_id"
          name="academic_year"
          :options="academicYears"
          label-name="name"
          value-name="id"
          label="Academic Year"
          rules="required"
          @input="resetSelectedTerm"
        />
        <lenon-select
          v-model="feeSetting.term_id"
          name="term"
          :options="filteredTerms"
          label-name="name"
          value-name="id"
          label="Term"
          rules="required"
        />
      </div>
      <lenon-input
        v-model="feeSetting.title"
        name="title"
        placeholder="Fee Title, eg. Student Bills"
        rules="required"
        label="Fee Title"
      />
      <div v-if="hasNoItems">
        <lenon-input
          v-model="feeSetting.amount"
          name="amount"
          placeholder="Enter Amount"
          rules="required|numeric"
          label="Fee Amount"
        />
      </div>
      <div v-else>
        <div class="row mb-1">
          <div class="col-12">
            <small><b>Total fee amount is the sum of the amounts of the individual fee items.</b></small>
          </div>
        </div>
        <div
          v-for="(item,index) in feeSetting.items"
          :key="index"
          class="row"
        >
          <div class="col-6">
            <lenon-input
              v-model="item.title"
              name="fee_item"
              placeholder="Item, eg. Tuition Fees"
              rules="required"
              label="Fee Item"
              :suffix-icon="null"
              :show-label="false"
            />
          </div>
          <div class="col-3">
            <lenon-input
              v-model="item.amount"
              name="item_amount"
              placeholder="Amount"
              rules="required|numeric"
              label="Item Amount"
              type="number"
              :suffix-icon="null"
              :show-label="false"
            />
          </div>
          <div class="col-3">
            <lenon-button
              :icon-only="true"
              icon="TrashIcon"
              variant="outline-danger"
              class="float-right"
              @onClick="removeItem(index)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-between align-items-center">
            <strong>Total Amount: GHS {{ totalAmount }}</strong>
            <lenon-button
              :icon-only="true"
              icon="PlusIcon"
              variant="outline-success"
              @onClick="addItem()"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-1">
          <b-form-checkbox
            v-model="hasNoItems"
            class="mb-1"
          >
            This Fee Has No Items
          </b-form-checkbox>
        </div>
      </div>
      <lenon-date-picker
        v-model="feeSetting.due_date"
        name="due_date"
        label="Due Date"
        placeholder="Due Date"
        rules="required"
      />
      <lenon-multi-select
        v-model="feeSetting.classes"
        value-name="id"
        label-name="name"
        name="classes"
        placeholder="Affected Classes"
        label="Affected Classes"
        :options="classes"
        rules="required"
      />
      <!--      <div v-html="missingClassText('fee wallet')" />-->
      <b-row>
        <div class="col-12 mt-1">
          <b-form-checkbox
            v-model="allClasses"
          >
            Select All Classes
          </b-form-checkbox>
        </div>
        <div class="col-12 mt-1">
          <small><strong>Please check this option if you do not want students in selected classes to be billed immediately.</strong></small>
        </div>
        <div class="col-12">
          <div class="d-flex justify-content-between">
            <b-form-checkbox
              v-model="feeSetting.exclude_students"
            >
              Exclude Students
            </b-form-checkbox>
          </div>
        </div>
        <div class="col-12 mt-1">
          Notify Parents
        </div>
        <div class="col-12">
          <div class="d-flex">
            <b-form-checkbox
              v-model="feeSetting.notify_parents_sms"
              class="mb-1 mr-1"
            >
              SMS (PAID)
            </b-form-checkbox>
            <b-form-checkbox
              v-model="feeSetting.notify_parents_push"
              class="mb-1"
            >
              PUSH (FREE)
            </b-form-checkbox>
          </div>
        </div>
      </b-row>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeFeeSettingModal()"
          />
          <lenon-button
            :label="updateFeeSettingMode?'Update':'Create'"
            :disabled="invalid || itemsInvalid"
            :loading="feeSettingLoading"
            loading-text="Loading..."
            @onClick="updateFeeSettingMode?updateFeeSetting():createFeeSetting()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>

import {
  BRow, BDropdownItem, BDropdownDivider, BFormCheckbox, BTooltip,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import {
  CREATE_FEE_SETTING_M,
  DELETE_FEE_SETTING_M,
  UPDATE_FEE_SETTING_M,
} from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'
import logData from '@/libs/log'
import { missingClassText } from '@/libs/dnd'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'

export default {
  name: 'FeeSettingsSetup',
  components: {
    ErrorDisplay,
    LenonMultiSelect,
    LenonDatePicker,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BFormCheckbox,
  },
  directives: {
    BTooltip,
  },
  mixins: [showToast, confirm],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customAy: false,
      hasNoItems: false,
      error: {},
      missingClassText,
      allClasses: false,
      deletingFeeSetting: false,
      feeSettingModalOpened: false,
      updateFeeSettingMode: false,
      feeSettingLoading: false,
      selectedFeeSettingId: null,
      feeSetting: {
        id: null,
        title: null,
        academic_year_id: null,
        term_id: null,
        amount: null,
        due_date: null,
        exclude_students: false,
        notify_parents_sms: false,
        notify_parents_push: false,
        classes: [],
        items: [],
      },
    }
  },
  computed: {
    itemsInvalid() {
      return !this.hasNoItems && this.feeSetting.items.length === 0
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    terms() {
      return this.$store.getters['termsClasses/terms']
    },
    filteredTerms() {
      return this.terms.filter(t => this.termIds.includes(t.id))
    },
    termIds() {
      if (this.academicYear) {
        return this.academicYear.terms.map(t => t.term_id)
      }
      return []
    },
    academicYear() {
      return this.academicYears.find(ay => ay.id === this.feeSetting.academic_year_id)
    },
    totalAmount() {
      return this.feeSetting.items.map(s => +s.amount).reduce((prev, curr) => (+prev) + (+curr), 0).toFixed(2)
    },
    feeSettings() {
      return this.$store.getters['feeSettings/feeSettings']
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
    filteredClasses() {
      let selectedClasses = []
      // compile already selected classes
      this.feeSettings.forEach(gs => {
        selectedClasses = [...selectedClasses, ...gs.classes.map(c => c)]
      })
      // return classes not selected yet
      return this.classes.filter(cls => !selectedClasses.includes(cls.id))
    },
  },
  watch: {
    allClasses(checked) {
      if (checked) {
        this.feeSetting.classes = this.classes
      } else {
        this.feeSetting.classes = []
      }
    },
    modalOpened(opened) {
      this.feeSettingModalOpened = opened
    },
    selectedFeeSettingId(id) {
      if (!id) {
        this.resetFeeSettingForm()
      }
    },
  },
  methods: {
    resetSelectedTerm() {
      this.feeSetting.term_id = null
    },
    removeItem(index) {
      this.feeSetting.items.splice(index, 1)
    },
    addItem() {
      this.feeSetting.items.push({ title: null, amount: null })
    },
    resetFeeSettingForm() {
      this.selectedFeeSettingId = null
      this.updateFeeSettingMode = false
      this.customAy = false
      this.feeSetting = {
        id: null,
        title: null,
        academic_year_id: null,
        term_id: null,
        amount: null,
        due_date: null,
        exclude_students: false,
        notify_parents_sms: false,
        notify_parents_push: false,
        classes: [],
        items: [],
      }
      this.hasNoItems = false
      this.$refs.feeSettingForm.reset()
    },
    populateFeeSettingForm(fs) {
      if (this.selectedFeeSettingId) {
        this.updateFeeSettingMode = true
        const fee = this
          .feeSettings.find(g => +g.id === +this.selectedFeeSettingId)
        this.feeSetting = {
          ...fee,
          classes: fee.classes.map(c => this.classes.find(cls => +cls.id === +c)),
          exclude_students: false,
          notify_parents_sms: false,
          notify_parents_push: false,
        }
        this.hasNoItems = this.feeSetting.items?.length === 0
        // eslint-disable-next-line no-underscore-dangle
        delete this.feeSetting.__typename
        delete this.feeSetting.display_date
        delete this.feeSetting.status
        delete this.feeSetting.term
        delete this.feeSetting.academic_year
        delete this.feeSetting.display_name
        delete this.feeSetting._showDetails
      } else {
        this.resetFeeSettingForm()
      }
    },
    closeFeeSettingModal() {
      this.feeSettingModalOpened = false
      this.$emit('modalClosed')
    },
    updateFeeSetting() {
      this.error = {}
      if (!this.feeSetting.id) {
        return
      }
      this.feeSettingLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_FEE_SETTING_M,
        variables: {
          input: {
            ...this.feeSetting,
            classes: this.feeSetting.classes.map(c => c.id),
            amount: +this.feeSetting.amount,
            items: this.hasNoItems ? [] : this.feeSetting.items.map(c => ({ ...c, amount: +c.amount })),
          },
        },
      })
        .then(res => {
          this.feeSettingLoading = false
          this.showSuccess('Updated fee setting successfully')
          this.$store.commit('feeSettings/updateFeeSetting', res.data.updateFeeSetting)
        })
        .catch(err => {
          this.error = err
          logData(err)
          this.showError('Failed to update fee setting')
          this.feeSettingLoading = false
        })
    },
    createFeeSetting() {
      this.error = {}
      if (!this.customAy) {
        this.feeSetting.academic_year_id = null
        this.feeSetting.term_id = null
      }
      this.feeSettingLoading = true
      this.$apollo.mutate({
        mutation: CREATE_FEE_SETTING_M,
        variables: {
          input: {
            ...this.feeSetting,
            classes: this.feeSetting.classes.map(c => c.id),
            amount: +this.feeSetting.amount,
            items: this.hasNoItems ? [] : this.feeSetting.items.map(c => ({ ...c, amount: +c.amount })),
          },
        },
      })
        .then(res => {
          this.showSuccess('Created fee setting successfully')
          this.feeSettingLoading = false
          this.$store.commit('feeSettings/addFeeSetting', res.data.createFeeSetting)
          this.resetFeeSettingForm()
        })
        .catch(err => {
          this.error = err
          logData(err)
          this.showError('Failed to create fee setting')
          this.feeSettingLoading = false
        })
    },
    deleteFeeSetting() {
      if (!this.selectedFeeSettingId) {
        this.showInfo('Please select a fee setting ')
        return
      }
      this.deletingFeeSetting = true
      this.$apollo.mutate({
        mutation: DELETE_FEE_SETTING_M,
        variables: { id: this.selectedFeeSettingId },
      })
        .then(() => {
          this.deletingFeeSetting = false
          this.showSuccess('Deleted fee setting successfully')
          this.$store.commit('feeSettings/removeFeeSetting', this.selectedFeeSettingId)
          this.selectedFeeSettingId = null
          this.resetFeeSettingForm()
        })
        .catch(err => {
          logData(err)
          this.showError('Failed to delete fee setting')
          this.deletingFeeSetting = false
        })
    },
  },
}
</script>
