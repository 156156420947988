<template>
  <div class="academic-years">
    <lenon-list-page
      title="Fee Settings"
      :columns="columns"
      :rows="feeSettings"
      :table-loading="tableLoading"
      :show-create="false"
      :show-refresh="false"
      :show-search="true"
      :show-details="true"
      sort-column="display_date"
      sort-dir="desc"
      search-placeholder="Search"
    >
      <template slot="right-extra-header-actions">
        <div
          v-responsive="$store.getters['app/largeScreen']"
          class="d-flex align-self-center align-items-start"
        >
          <lenon-button
            icon="SettingsIcon"
            label="Fee Setting"
            tool-tip-text="Manage Fee Settings(CRUD)"
            class=""
            @onClick="showFeeSettingModal()"
          />
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-start"
        >
          <lenon-dropdown left>
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Fee Setting"
              @click="showFeeSettingModal()"
            />
          </lenon-dropdown>
        </div>
      </template>
      <template #row-details="{row}">
        <b-card>
          <b-row
            class="mb-2"
          >
            <b-col
              md="12"
              class="mb-1"
            >
              <strong>Classes </strong>
              <b-row class="px-1">
                <div
                  v-for="(school_class,index) in row.item.classes"
                  :key="index"
                  style="margin-bottom: 5px; margin-right: 3px;"
                >
                  <b-badge variant="light-success">
                    {{ reducedClasses[+school_class] }}
                  </b-badge>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </template>
      <template #index="{row}">
        {{ row.index + 1 }}
      </template>
      <template #status="{row}">
        <b-badge :variant="getStatusVariant(row.item.status)">
          {{ getStatusText(row.item.status) }}
        </b-badge>
      </template>
      <template #actions="{row}">
        <lenon-button
          icon-only
          variant="flat-primary"
          :icon="row.detailsShowing?'EyeOffIcon':'EyeIcon'"
          @onClick="row.toggleDetails"
        />
      </template>
    </lenon-list-page>

    <fee-setting-setup
      :modal-opened="feeSettingModalOpened"
      @modalClosed="closeModal('feeSetting')"
    />
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import FeeSettingSetup from '@/views/finance/fee-settings/FeeSettingsSetup.vue'
import { FETCH_FEE_SETTINGS_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import {
  BBadge, BCard, BCol, BRow,
} from 'bootstrap-vue'
import logData from '@/libs/log'
import LenonDropdown from '@/lenon/components/LenonDropdown'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'

export default {
  name: 'FeeSettings',
  components: {
    LenonDropdownItem,
    LenonDropdown,
    LenonListPage,
    FeeSettingSetup,
    LenonButton,
    BCard,
    BRow,
    BCol,
    BBadge,
  },
  mixins: [showToast],
  data() {
    return {
      feeSettingModalOpened: false,
      tableLoading: false,
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'amount',
          label: `Amount (${this.$store.getters['auth/currency']})`,
        },
        {
          key: 'display_date',
          label: 'Due Date',
          sortable: true,
        },
        {
          key: 'term',
          label: 'Term',
        },
        {
          key: 'academic_year',
          label: 'Academic Year',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'actions',
          label: 'Details',
        },
      ],
      reducedClasses: {},
    }
  },
  computed: {
    feeSettings() {
      return this.$store.getters['feeSettings/feeSettings']
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    classes(cls) {
      this.reduceCurrentClasses()
    },
  },
  mounted() {
    this.fetchFeeSettings()
    this.reduceCurrentClasses()
  },
  methods: {
    reduceCurrentClasses() {
      this.reducedClasses = this.classes.reduce((cumulatedValue, currentValue) => {
        // eslint-disable-next-line no-param-reassign
        cumulatedValue[+currentValue.id] = currentValue.name
        return cumulatedValue
      }, {})
    },
    getStatusText(status) {
      let text = 'In Progress'
      // eslint-disable-next-line default-case
      switch (+status) {
        case 1:
          text = 'In Progress'
          break
        case 0:
          text = 'Due'
          break
      }
      return text
    },
    getStatusVariant(status) {
      let variant = 'light-success'
      // eslint-disable-next-line default-case
      switch (+status) {
        case 1:
          variant = 'light-success'
          break
        case 0:
          variant = 'light-danger'
          break
      }
      return variant
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'feeSetting':
          this.feeSettingModalOpened = false
          break
      }
    },
    showFeeSettingModal() {
      this.feeSettingModalOpened = true
    },

    fetchFeeSettings() {
      this.tableLoading = this.feeSettings.length < 1
      this.$apollo.query({ query: FETCH_FEE_SETTINGS_Q })
        .then(res => {
          this.tableLoading = false
          this.$store.commit('feeSettings/setFeeSettings', res.data)
        })
        .catch(err => {
          this.tableLoading = false
          logData(err)
          this.showError('Failed to load fee wallet')
        })
    },
  },
}
</script>
